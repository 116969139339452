import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  children?: React.ReactNode;
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  selectedAction: string | null;
  searchText: string,
  brandAnchorEl: HTMLElement | null;
  newCompanyName:string;
  userId: number;
  value: number | 0;
 
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationIndustryController extends BlockComponent<
Props,
S,
SS
> {
  // Customizable Area Start
  subScribedMessages: any[] = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
   // Customizable Area Start
      sidebarToggle: false,
      userId:0,
      activeComponent: "Company",
      anchorEl: null,
      selectedAction: null,
      openDialog: false,
      newCompanyName:'',
      searchText: '',
      brandAnchorEl: null,
      value: 0,
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
     await super.componentDidMount?.();
// Customizable Area End
  }

  // Customizable Area Start
 
  handleClose = () => {
    this.setState({ openDialog: false})
  };

  handleComOpen = () => {
    this.setState({ openDialog: true})
  };
  handleIndustryMarket=(event:React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.value
    this.setState({newCompanyName:name})
  }

  handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)

  }
 
  // Customizable Area End
}
