import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {  MouseEvent } from 'react'
import { Message } from "../../../framework/src/Message";



// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  totalResults: number;
  resultperPage : number;
  currentPage: number;
  isModalOpen: boolean; 
  selectedProduct: string;
  catalogueValue : string;
  isMenuOpen :boolean;
  isMapingModal : boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalougedetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   sidebarToggle: false,
   openDialog: true,
   activeComponent: "Company",
   anchorEl: null,
   value: 12,
   totalResults: 140, 
   resultperPage: 12, 
   currentPage: 1 ,
   isModalOpen: false, 
   catalogueValue: "",
selectedProduct: "",
isMenuOpen: false, 
isMapingModal : false
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount(); 
// Customizable Area End
  }

  // Customizable Area Start
  getResultText = (page: number): string => {
    const { resultperPage, totalResults } = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start} - ${end} of ${totalResults} results`;
  }

  handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = (): void => {
    this.setState({ anchorEl: null });
  };

  navigateToBrandCatalogue = () => {
    const brandCatalogueRoute = '/BrandCatalouges'; 
    window.location.href = brandCatalogueRoute; 
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedProduct: event.target.value });
  };

  handleOpenModalMapping = () => {
    this.setState({ isMapingModal: true });
  };

  handleCloseModalMapping = () => {
    this.setState({ isMapingModal: false });
  };

  // Customizable Area End
}