export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const logoImage = require("../assets/logo.svg");
export const productsImage = require("../assets/products.svg");
export const catalogeImage = require("../assets/catalouge.svg");
export const documentImage = require("../assets/Document.svg");
export const filterImage = require("../assets/filter.svg");
export const plumbingImg = require("../assets/plumbing.svg");
export const pipesImg = require("../assets/pipes.svg");
export const pipes2Img = require("../assets/pipes2.svg");
export const downImage = require("../assets/downarrow.svg");
export const prodImg = require("../assets/Productimg.svg");
export const watchImg = require("../assets/watch.svg");
export const arrowImg = require("../assets/arrow.svg");
export const prodImg1 = require("../assets/product.png");
export const navarrowImg = require("../assets/navarrow.svg");
export const oproductsImage = require("../assets/Oproduct.svg");
export const ocatalogeImage = require("../assets/Ocatalouge.svg");
export const odocumentImage = require("../assets/Odocument.svg");
export const seacrhIcon = require("../assets/search.svg");
export const filterIcon = require("../assets/filter.svg");
export const sortingIcon = require("../assets/sorting.svg");
export const UploadImg = require("../assets/uplaod.svg");
export const FilesImg = require("../assets/files.svg");
export const saveImg = require("../assets/save.svg");
export const Catalougeproduct = require("../assets/Catalouge.svg");
export const Editimg = require("../assets/edit.svg");
export const Deleteimg = require("../assets/cancel.svg");
export const cancleImg = require("../assets/cancel1.svg");
export const downImg = require("../assets/down.svg");
export const AddImg = require("../assets/add.svg");





