import React from "react";


import {
    // Customizable Area Start
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Dialog,
    DialogContent,
    styled,
    TextField,
    TableCell,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar.web"
import Navbar from "../../../components/src/Navbar.web";
import ApplicationIndustryController, { Props } from "./ApplicationIndustryController.web";
import { BoxProps } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';

const CompanyNameInput = styled(TextField)({
    width: "520px",
    height: "60px",
    borderRadius: "5px",
})

// Customizable Area End
const IndustryMarket = ["Chemical","Food" ,"Automobiles","Real Estate", "Biotechnology", "Defence", "Healthcare", "Technology", "Agriculture","Aviation" , "Capital Goods", "Commercial Services", "Construction", "Education"]

export default class ApplicationIndustry extends ApplicationIndustryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPopup = () => {
        const BoxView = Box as React.FC<BoxProps>;
        return (
            <>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    style={{ borderRadius: "50px" }}
                    PaperProps={{
                        style: {
                            borderRadius: "8px", width: "600px", height: "300px",
                        },
                    }}
                    data-testid="dialog"

                >
                    <BoxView
                        display={"flex"}
                        style={{
                            cursor: "pointer",
                            justifyContent: "space-between",
                            margin: "5%",
                            marginBottom: "0px",
                        }}
                       data-testid="close-dialog-button"
                        onClick={this.handleClose}
                    >
                        <Typography style={{ fontWeight: 700, fontSize: "24px", color: "#272727", fontFamily: 'Inter, sans-serif' }}>Add New Industry / Market</Typography>
                        <CloseIcon />
                    </BoxView>
                    <DialogContent
                        style={{
                            lineHeight: "26px", padding: "22px", color: "#000000", fontSize: "24px", fontFamily: "Panton Narrow Bold", overflowX: "hidden", overflowY: "hidden",
                            fontWeight: 700
                        }}
                    >
                        <div style={{ width: "100%", border: "1px solid #E2E8F0" }}></div>
                        <Box style={{ marginTop: "35px" }}>
                            <Typography style={{ fontSize: "14px", fontWeight: 700, color: "#334155", paddingBottom: "4px" }}>Industry / Market</Typography>
                            <CompanyNameInput
                                variant="outlined"
                                size="small"
                                placeholder="Enter Industry / Market"
                                data-testid="company-name-input"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleIndustryMarket(event)}
                                InputProps={{ style: { borderRadius: "5px", border: "1px solid transparent" } }}
                            />
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "end", marginTop: "19px", gap: "20px" }}>
                            <Button style={{
                                height: "44px",
                                borderRadius: "8px",
                                width: "120px",
                                fontFamily: 'Inter, sans-serif',
                                border: "1px solid #FF6600",
                                fontSize: "14px",
                                backgroundColor: "#FF6600",
                                color: "white",
                                fontWeight: 700,
                                textTransform: "capitalize"
                            }}
                                data-test-id="add-new-company-btn"
                            >Add</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    cartTable = () => {
        const itemsPerRow = 5;
        const rows = [];
        for (let i = 0; i < IndustryMarket.length; i += itemsPerRow) {
            const rowItems = IndustryMarket.slice(i, i + itemsPerRow);
            rows.push(
                <TableRow key={`row-${i}`}>
                    {rowItems.map((item, index) => (
                        <TableCell key={`cell-${i}-${index}`} align="center" style={{border:'none'}}>
                            <Box
                                style={{
                                    border: "1px solid #E2E8F0",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "100px",
                                    width:"122px",
                                    display: "flex",
                                    alignItems: "end",
                                    justifyContent: "center",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    textAlign: "center",
                                    color:" rgb(50, 56, 66);",
                                
                                }}
                            >
                                {item}
                            </Box>
                        </TableCell>
                    ))}
                </TableRow>
            );
        }

        return (
            <Box style={{ width: "94%", ...webStyle.mainContainer }}>
                <Box style={webStyle.header}>
                    <Typography style={{ fontSize: "14px", fontWeight: 700, color: "#334155", paddingBottom: "4px" }}>Industry / Market</Typography>
                </Box>
                <Box style={webStyle.header}>
                    <Typography variant="h6" style={webStyle.heading}>
                        Industry / Market
                    </Typography>
                    <Button variant="contained" className="addNewCompany" data-testid="open-dialog-button"
 onClick={this.handleComOpen} style={{ ...webStyle.checkoutButton, textTransform: "none", boxShadow: "none" }}>
                        Add New Industry / Market
                    </Button>
                    {this.renderPopup()}
                </Box>
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
                    
                    <TableContainer>
    <Table aria-label="Industry Market Table">
        <TableBody style={webStyle.Tbody}>
            {rows}
        </TableBody>
    </Table>
</TableContainer>
                </Box>
            </Box>
        );
    }
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigate} />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.cartTable()}
                </SideBar>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle: {
    [key: string]: {
        [property: string]: string | number
    }
} = {
    mainContainer: {
        marginLeft: "47px",
        fontFamily: "Inter, sans-serif",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",
        fontFamily: "Inter, sans-serif",

    },
    heading: {
        fontFamily: "Inter, sans-serif",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "26px",
        textAlign: "left",

    },
    checkoutButton: {
        backgroundColor: "#ff6600",
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        width: "max-content",
    },
    Tbody: {
        padding: "20px",
    }, 
    tableHead: {
        backgroundColor: "#f3f4f6",
    },
    TableCell: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
    },
    tableBody: {
        width: "1045px",
        padding: "15px",
    },
   
  
};

// Customizable Area End