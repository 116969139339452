import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {  MouseEvent } from 'react'

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  totalResults: number;
  resultperPage : number;
  currentPage: number;
  CatalougeitemList: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      image: { url: string };
      industry: string;
      no_of_catalogues: number;
      brand_certificates: [];
    };
  }[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BrandcatalougeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCatalougeItemList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   sidebarToggle: false,
   openDialog: true,
   activeComponent: "Company",
   anchorEl: null,
   value: 12,
   totalResults: 140, 
   resultperPage: 12, 
   currentPage: 1 ,
   CatalougeitemList: [],
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount(); 
    this.getAllcatalougeitem();
// Customizable Area End
  }

  // Customizable Area Start
  receive = async (from: String, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (responseJson?.error) {
        this.parseApiErrorResponse(responseJson.error);
        this.parseApiCatchErrorResponse(responseJson.error);
        return;
      }
  
      const responseHandlers = {
        [this.getAllCatalougeItemList]: () => {
          this.setState({ CatalougeitemList: responseJson.data || [] });
          
        },
      };
      if (responseHandlers[apiRequestCallId]) {
        responseHandlers[apiRequestCallId]();
      }
    }
  };

  getAllcatalougeitem = async() => { 
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllCatalougeItemList = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getallcatalougeItem}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value });
  };

  getResultText = (page: number): string => {
    const { resultperPage, totalResults } = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }

  handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };


  handleMenuClose = (): void => {
    this.setState({ anchorEl: null });
  };

  // Customizable Area End
}
