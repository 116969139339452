import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Table,
  TableContainer,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import { AddImg, downImage, FilesImg, saveImg, UploadImg } from "./assets";

import AddbrandsController, {
  Props,
} from "./AddbrandsController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"
import { Autocomplete } from "@mui/material";
// Customizable Area End

export default class Addbrands extends AddbrandsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Addbrands = () => {
    const tabData = [
      { label: 'Brand Details', value: 0 },
      { label: 'Brand Contact Information', value: 1 },
      { label: 'Region of Operations', value: 2 },
      { label: 'Business Information', value: 3 },
      { label: 'Brand Documents', value: 4 },
    ];

    const options = [
      {
          label: "AAC Blocks 4”",
          size: "Size: 625x240x100mm Make: Alpiine",
      },
  ];
  

  const renderTabContent = () => {
    switch (this.state.value) {
      case 0:
        return renderBrandDetails();
      case 1:
        return renderBrandConDetails();
      case 2:
        return renderOtherTabs();
      case 3:
        return renderBusinessInformation();
      default:
        return renderBrandDocument();
    }
  };

  

    const renderBrandDetails = () => {
      return (
        <>
          <Box style={{ padding: "20px" , borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  placeholder="Enter Brand Name"
                  onChange={this.handlechange}
                  value={this.state.branddetail.brandName}
                  inputProps={{
                    'data-testid': 'brand-name-input',
                    style: {
                      fontWeight: 400,
                      fontSize: "16px",
                      fontFamily: "Inter, sans-serif",
                      color: "#BCC1CA",
                    },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },}}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Industry / Sector</Typography><span style={{  fontWeight: 700,color: "#DC2626", marginTop : "2px" }}>*</span>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={options}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Industry / Sector"
      value={this.state.branddetail.industrySector}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontSize: "14px",
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          padding: "6px 8px",
          height: "40px",
          borderRadius: "8px",
          background: "#F3F4F6",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontSize: "16px", 
          fontWeight  :400,
          fontStyle: "Inter,sans-serif", 
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      maxHeight: "200px",
      boxShadow: "none",
      border: "1px solid #F3F4F6",
      borderRadius: "8px",
    },
  }}
/>
              </Box>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Brand Tagline</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
              </Box>            
            <TextField
                placeholder="Enter Brand Tagline"
                onChange={this.handlechange}
                value={this.state.branddetail.brandTagline}
                inputProps={{
                  style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                }}   
                style={{
                  ...webStyle.formField, width: "100%", height: "40px", borderRadius: "8px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                }}
              />
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Brand Logo</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
              </Box> 
                <Box
                  style={{
                    height: "256px",  
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F3F4F6",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    onChange={this.handleFileChange}
                    data-testId="product-brand-image"
                  />
                  <label htmlFor="upload-file">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label><br />
                  <Typography style={{ fontFamily: "Inter, sans-serif", marginTop: "3px", fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                  <Box style={{ display: "flex", marginLeft: "7px" }} onChange={this.handleFileChange} >
                    <Typography component="span" style={{
                      textDecoration: "underline", cursor: "pointer", color: "#FF6600", fontFamily: "Inter,sans-serif"
                      , fontSize: "14px", fontWeight: 400, marginTop: "8px"
                    }}>
                      Browse Files
                    </Typography>
                    <img src={FilesImg} alt="FilesImg" style={{ width: "18px", height: "18px", margin: "7px 8px" }} />
                  </Box>
                </Box>
              </Box>
              <Box style={webStyle.formItem}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Brand Description</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
              </Box> 
                 <TextField
                  placeholder="Description"
                  onChange={this.handlechange}
                  value={this.state.branddetail.brandDescription}
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                     style={{
                    ...webStyle.formField, width: "100%", height: "257px", borderRadius: "8px", background: "#F3F4F6",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderBrandDocument = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Certificate Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                placeholder="Enter Brand Name"
                onChange={this.handlechange}
                inputProps={{
                  style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                }}                style={{
                  ...webStyle.formField, width: "100%", height: "40px", borderRadius: "8px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "8px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                }}
              />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>License</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <Autocomplete
                  id="catalogue-field"
                  disablePortal
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a License"
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontSize: "16px", 
                          fontWeight  :400,
                          fontStyle: "Inter,sans-serif", 
                          color: "#BCC1CA", 
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "14px",
                          fontFamily: "Inter, sans-serif",
                          color: "#1E293B",
                          padding: "10px",
                          height: "40px",
                          borderRadius: "8px",
                          background: "#F3F4F6",
                        },
                        endAdornment: (
                          <img
                            src={downImage}
                            alt="Dropdown Icon"
                            style={{ width: "12px", height: "7px", marginRight: "8px" }}
                          />
                        ),
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      borderRadius: "8px",
                      maxHeight: "200px",
                      boxShadow: "none",
                      border: "1px solid #F3F4F6",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box>
                <Box
                  style={{
                    height: "256px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F3F4F6",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    onChange={this.handleFileChange}
                  />
                  <label htmlFor="upload-file">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label><br />
                  <Typography style={{ fontFamily: "Inter, sans-serif", marginTop: "3px", fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                  <Box style={{ display: "flex", marginLeft: "7px" }} onChange={this.handleFileChange} >
                    <Typography component="span" style={{
                      textDecoration: "underline", cursor: "pointer", color: "#FF6600", fontFamily: "Inter,sans-serif"
                      , fontSize: "14px", fontWeight: 400, marginTop: "8px"
                    }}>
                      Browse Files
                    </Typography>
                    <img src={FilesImg} alt="FilesImg" style={{ width: "18px", height: "18px", margin: "7px 8px" }} />
                  </Box>
                </Box>
              </Box>
              <Box
                  style={{
                    height: "256px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F3F4F6",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    onChange={this.handleFileChange}
                  />
                  <label htmlFor="upload-file">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label><br />
                  <Typography style={{ fontFamily: "Inter, sans-serif", marginTop: "3px", fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                  <Box style={{ display: "flex", marginLeft: "7px" }} onChange={this.handleFileChange} >
                    <Typography component="span" style={{
                      textDecoration: "underline", cursor: "pointer", color: "#FF6600", fontFamily: "Inter,sans-serif"
                      , fontSize: "14px", fontWeight: 400, marginTop: "8px"
                    }}>
                      Browse Files
                    </Typography>
                    <img src={FilesImg} alt="FilesImg" style={{ width: "18px", height: "18px", margin: "7px 8px" }} />
                  </Box>
                </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
             <Box style={{display:"flex"}}><img alt="AddImg" src={AddImg}/><Typography style={{...webStyle.AddType}}>Add another Certificate</Typography> </Box>
             <Box style={{display:"flex"}}><img src={AddImg} alt="AddImg"/><Typography style={{...webStyle.AddType}}>Add another License</Typography> </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderBusinessInformation = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Legal Business Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Legal Business Name"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}  
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },}}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Tax Identification Number (TIN/VAT/GST)*</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Tax Identification Number"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}  
                />  
              </Box>  
            </Box>  
          </Box>  
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box>
              <Typography style={{...webStyle.Title}}>
              Business Address
              </Typography>
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Street</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Rammurthy nagar"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}        
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },}}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>City</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Bangalore"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={{...webStyle.formGrid, marginTop:"24px"}}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>State</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Karnataka"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}  
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },}}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Country</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="India"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={{...webStyle.formGrid, marginTop : "20px"}}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Zip Code</Typography></Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="560016"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderBrandConDetails = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Primary Contact Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Enter Contact Name"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}  
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },}}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Primary Phone Number</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Enter Phone Number"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box style={{...webStyle.formGrid, marginTop : "20px"}}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Alternative Contact Information</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Enter Alternative Contact Information"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif",color: "#1E293B", },}}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Customer Support Contact Details</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Enter Customer Support Contact Details"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
              </Box>
            </Box>
            <Box style={{...webStyle.formGrid, marginTop : "20px"}}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Email Address</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  placeholder="Enter Customer Support Contact Details"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

       const renderOtherTabs = () => {
      return (
        <>
        <Box style={{ padding: "20px",  borderBottom: "1px solid #E1E7EF"}}>
         <Box style={{...webStyle.formGrid}}>
         <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Select City</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <Autocomplete
                  id="catalogue-field"
                  disablePortal
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontSize: "16px",
                          fontWeight: 400,
                          fontStyle: "Inter,sans-serif",
                          color: "#BCC1CA",
                        },
                      }}
                      placeholder="Select State"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "14px",
                          fontFamily: "Inter, sans-serif",
                          color: "#1E293B",
                          padding: "10px",
                          height: "40px",
                          borderRadius: "8px",
                          background: "#F3F4F6",
                        },
                        endAdornment: (
                          <img
                            src={downImage}
                            alt="Dropdown Icon"
                            style={{ width: "12px", height: "7px", marginRight: "8px" }}
                          />
                        ),
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      borderRadius: "8px",
                      maxHeight: "200px",
                      boxShadow: "none",
                      border: "1px solid #F3F4F6",
                    },
                  }}
                />
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Region</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={this.handlechange}
                  inputProps={{
                    'data-testid': 'brand-name-input',
                    style: {
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Inter, sans-serif",
                      color: "#BCC1CA",
                    },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },}}
                />
              </Box>
              <Box style={{ ...webStyle.formGrid }}>
             <Box style={{display:"flex"}}><img src={AddImg} alt="AddImg"/><Typography style={{...webStyle.AddType, margin: "auto 8px"}}>Add another Certificate</Typography> </Box>

            </Box>
            </Box>
        </Box>
             <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
          </>
      );
    };

    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography style={webStyle.Headingss}>
            Add New Brand
          </Typography>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px" }}
                    label={
                      <Typography
                        style={this.state.value === value ? webStyle.activeTab : webStyle.inactiveTab}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
            {renderTabContent()} 
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar   />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Addbrands()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",
  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter, sans-serif",
  },
  Headingss: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "1.5",
    textAlign: "left" as React.CSSProperties["textAlign"]
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
    alignItems: "start",
  },
    formItem: {
      display: 'flex' as 'flex',  
    flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',  
    width: '100%' as string, 
  },
  AddType:{
    fontFamily : "Inter, sans-serif",
    fontSize : "16px",
    fontWeight : 700,
    lineHeight : "24px",
    color : "#FF6600",
    marginLeft : "8px"
  },
  formField: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    background: '#F3F4F6',
    boxSizing: 'border-box' as 'border-box',  
  } as React.CSSProperties,  
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334155",
  },
  textFieldStyle: {
    fontSize: "14px",
    fontWeight: 400,
    backgroundColor: "#F3F4F6",
    border: "none",
    borderRadius: "8px",
    boxShadow: "none",
  },
  uploadContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  uploadButton: {
    backgroundColor: "#ff6600",
    color: "#fff",
    textTransform: "none",
    padding: "6px 12px",
    borderRadius: "8px",
  },
  saveButton: {
    margin : "16px 20px 16px 0px",
    boxShadow : "none",
  },
  formHeading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "20px",
  },
  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  navTabContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px",
    borderBottom: "1px solid #E1E7EF"
  },
  activeTab: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'center' as 'center',  
    color: '#EE6900',
  },
  inactiveTab: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left' as 'left', 
    color: '#565E6C',
  },
  Title:{
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    marginBottom : "20px"
  }
}
// Customizable Area End