import React from "react";

import {
    // Customizable Area Start
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Link,
    Modal,
    Backdrop,
    Fade,
    TextField,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CatalougedetailController, {
    Props
} from "./CatalougedetailController";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import {  Catalougeproduct, Deleteimg, downImg, Editimg } from "./assets";
import { Autocomplete, Pagination, PaginationItem } from "@mui/material";
// Customizable Area End

export default class Catalougedetail extends CatalougedetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    Catalougedata = () => {

        const options = [
            {
                label: "AAC Blocks 4”",
                size: "Size: 625x240x100mm Make: Alpiine",
                imgSrc: Catalougeproduct, 
            },
        ];

        const catalogueData = [
            {
                title: "AAC Blocks 4”",
                size: "Size: 625x240x100mm Make: Alpine",
                type: "Blackwork",
                category: "Construction Material",
                  image: Catalougeproduct,
            },
            {
                title: "AAC Blocks 6”",
                size: "Size: 625x240x150mm Make: Everest",
                 image: Catalougeproduct,
                type: "Brickwork",
                category: "Building Supplies",
            },
            {
                title: "Insulated AAC Blocks",
                 image: Catalougeproduct,
                size: "Size: 600x200x100mm Make: Alpine",
                type: "Blackwork",
                category: "Construction Material",
            },
            {
                 image: Catalougeproduct,
                title: "Lightweight AAC Blocks",
                size: "Size: 600x200x150mm Make: Supreme",
                type: "Brickwork",
                category: "AAC Products",
            },
            {
                title: "High-Density AAC Blocks",
                size: "Size: 625x250x100mm Make: Alpine",
                type: "Blackwork",
                category: "Building Blocks",
                image: Catalougeproduct,
            },
            {
                title: "Thermal AAC Blocks",
                size: "Size: 600x240x150mm Make: Everest",
                type: "Brickwork",
                image: Catalougeproduct,
                category: "Construction Supplies",
            },
            {
                image: Catalougeproduct,
                title: "Eco-Friendly AAC Blocks",
                size: "Size: 625x250x125mm Make: Alpine",
                type: "Blackwork",
                category: "Eco Products",
            },
            {
                title: "Premium AAC Blocks",
                size: "Size: 625x240x150mm Make: Everest",
                image: Catalougeproduct,
                type: "Brickwork",
                category: "Construction Material",
            },
            {
                title: "Standard AAC Blocks",
                image: Catalougeproduct,
                size: "Size: 625x240x125mm Make: Supreme",
                type: "Blackwork",
                category: "Building Supplies",
            },
        ];

        const breadcrumbs = [
            { label: "Brand Catalogues", },
            { label: "Catalogue 1" },
        ];

        const renderModal = () => {
            return (
                <Modal
                    open={this.state.isModalOpen}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade in={this.state.isModalOpen}>
                        <Box  style={{position: "absolute" as "absolute", top: "50%",left: "50%", transform: "translate(-50%, -50%)", width: 708, height: "auto", backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", padding: "24px 0px", borderBottom: "1px solid #E2E8F0" }}>
                                <Typography style={{ ...styles.modaltitle }}>
                                    Map Product
                                </Typography>
                                <img src={Deleteimg} alt="Deleteimg" style={{ width: "24px", height: "24px", margin: "auto 24px" }} data-testId="Closemodal" onClick={this.handleCloseModal} />
                            </Box>
                            <Box style={{ marginTop: "24px", width: "660px", margin: "24px auto" }}>
                                <label
                                    htmlFor="catalogue-field"
                                    style={{ display: "block",fontSize: "14px",fontWeight: 700,fontFamily: "Inter, sans-serif",color: "#1E293B",marginBottom: "8px",}}>
                                    Catalogue<span style={{color: "red"}}>*</span>
                                </label>
                                <TextField
                                    placeholder="Catalogue 14"
                                    inputProps={{ "data-testid": "-input" }}
                                    style={{
                                        ...styles.formField, width: "660px", height: "44px", borderRadius: "8px", background: "#F3F4F6",}}
                                    InputProps={{ disableUnderline: true, 
                                        style: {  padding: "10px",fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", }, }}
                                    onChange={(e) => this.setState({ catalogueValue: e.target.value })}
                                    data-testid="catalogue-textfield"
                                />
                            </Box>
                            <Box style={{ marginTop: "24px", width: "660px", margin: "24px auto" }}>
            <label
                htmlFor="catalogue-field"
                style={{
                    display: "block",
                    fontSize: "14px",
                    fontWeight: 700,
                    fontFamily: "Inter, sans-serif",
                    color: "#1E293B",
                    marginBottom: "8px",
                }}
            >
                Product<span style={{color: "red"}}>*</span>
            </label>
            <Autocomplete
  id="catalogue-field"
  disablePortal // Ensures dropdown stays within the modal
  options={options}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Product"
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontSize: "14px",
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          padding: "10px",
          height: "44px",
          border: "1px solid #E2E8F0",
          borderRadius: "8px",
          background: "#F3F4F6",
        },
        endAdornment: (
          <img
            src={downImg}
            alt="Dropdown Icon"
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      borderRadius: "8px",
      maxHeight: "200px",
      boxShadow: "none",
      border: "1px solid #F3F4F6",
    },
  }}
/>
        </Box>
                            <Box
                                style={{ marginTop: "24px", width : "100%",display: "flex", justifyContent: "flex-end",borderTop:"1px solid #E2E8F0"}}>
                                <Button
                                    variant="contained"
                                    style={{...styles.saveButton, margin: "30px 24px 30px 0px ", padding: "7px 24px"}}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            );
        };

        const RenderMappingModal = () => {
            return (
                <Modal
                    open={this.state.isMapingModal}
                    onClose={this.handleCloseModalMapping}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade in={this.state.isMapingModal}>
                        <Box  style={{position: "absolute" as "absolute", top: "50%",left: "50%", transform: "translate(-50%, -50%)", width: 708, height: "auto", backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", padding: "24px 0px", borderBottom: "1px solid #E2E8F0" }}>
                                <Typography style={{ ...styles.modaltitle }}>
                                Remove Mapping
                                </Typography>
                                <img src={Deleteimg} alt="Deleteimg" style={{ width: "24px", height: "24px", margin: "auto 24px" }} data-testId="Closemappingmodal" onClick={this.handleCloseModalMapping} />
                            </Box>
                            <Box style={{ marginTop: "24px", width: "660px", margin: "24px auto" }}>
                                <Typography>Are you sure want to remove mapping from Product <span style={{fontFamily:"Inter,sans-serif", fontSize:"14px", fontWeight:700}}>AAC Blocks 4?</span></Typography>
                            </Box>
                            <Box
                                style={{ display : "flex",marginTop: "24px", width : "100%", justifyContent: "flex-end",borderTop:"1px solid #E2E8F0"}}>
                                <Button
                                    variant="contained"
                                    style={{...styles.Nobutton, margin: "30px 16px 30px 0px ", padding: "7px 24px"}}
                                    onClick={this.handleCloseModalMapping}
                                >
                                    No
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{...styles.Yesbutton, margin: "30px 24px 30px 0px ", padding: "7px 24px"}}
                                >
                                    Yes
                                </Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            );
        };

        return (
            <Box style={{ ...styles.mainContainer, width: "94%", }}>
                <Box style={{ ...styles.breadcrumbContainer, marginTop: "36px" }}>
                    {breadcrumbs.map((breadcrumb, index) => (
                        <React.Fragment key={index}>
                            <Link
                                onClick={() => {
                                    if (breadcrumb.label === "Brand Catalogues") {
                                        this.navigateToBrandCatalogue();
                                    } else {
                                    }
                                }}
                                style={{
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    fontFamily: "Inter, sans-serif",
                                    color: "#1E293B",
                                    cursor: "pointer",
                                }}
                            >
                                {breadcrumb.label}
                            </Link>
                            {index < breadcrumbs.length - 1 && (
                                <Typography style={styles.separator}>{" > "}</Typography>
                            )}
                        </React.Fragment>
                    ))}
                </Box>
                <Box style={styles.header}>
                    <Typography variant="h5" style={styles.heading}>
                        Catalogue 1
                    </Typography>
                    <Button
                        style={styles.checkoutButton}
                        onClick={this.handleOpenModal}
                        data-testId="MapProduct"
                    >
                        Map Product
                    </Button>
                </Box>
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px", overflow: "hidden" }}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead style={{ ...styles.tableHead }}>
                                <TableRow style={{ borderBottom: "none" }}>
                                    <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    <TableCell align="left" style={{ fontSize: "14px", fontWeight: 700, lineHeight: "22px", textAlign: "left", color: "#565E6C", backgroundColor: "#F3F4F6", borderBottom: "none", fontFamily: "Inter, sans-serif", }}>
                                        Product
                                    </TableCell>
                                    <TableCell align="left" style={{ fontSize: "14px", fontWeight: 700, lineHeight: "22px", textAlign: "left", color: "#565E6C", backgroundColor: "#F3F4F6", borderBottom: "none", fontFamily: "Inter, sans-serif", }}>
                                        Category
                                    </TableCell>
                                    <TableCell align="left" style={{ ...styles.TableCell, borderBottom: "none", textAlign: 'left' }}>
                                        Actions
                                    </TableCell>
                                    <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={styles.Tbody}>
                                <TableRow style={{ height: "30px" }}></TableRow>
                                {catalogueData.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            style={{
                                                width: "0%",
                                                borderBottom: "none",
                                                borderRight: "1px solid #E7E2F0",
                                            }}
                                        ></TableCell>
                                        <TableCell style={{ borderTop: "1px solid #E7E2F0" }}>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <img src={item.image} alt={item.title} style={{ marginRight: "10px" }} />
                                                <Box>
                                                    <Typography style={{ ...styles.title }}>{item.title}</Typography>
                                                    <Typography style={{ ...styles.size }}>{item.size}</Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell style={{ borderTop: "1px solid #E7E2F0" }}>
                                            <Box>
                                                <Typography style={{ ...styles.type }}>{item.type}</Typography>
                                                <Typography style={{ ...styles.category }}>{item.category}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left" style={{ borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF" }}>
                                            <IconButton
                                                aria-label="more"
                                                data-testId="MoreVertIcon"
                                                onClick={this.handleMenuClick}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={this.state.anchorEl}
                                                keepMounted
                                                data-testId="MoreVertIcon"
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={this.handleMenuClose}
                                                PaperProps={{
                                                    style: { boxShadow: 'none', border: "1px solid #E7E2Ef", width: "177px", marginTop: "20px" },
                                                }}
                                            >
                                                <MenuItem data-testId="EditMenuItem">
                                                    <Box style={{ display: "flex" }}>
                                                        <img src={Editimg} alt="Editimg" style={{ width: "18px", height: "18px" }} />
                                                        <Typography style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Inter, sans-serif" }}>Edit</Typography>
                                                    </Box>
                                                </MenuItem>
                                                <MenuItem onClick={this.handleOpenModalMapping} data-testId="Removemaping">
                                                    <Box style={{ display: "flex" }} >
                                                        <img src={Deleteimg} alt="Editimg" style={{ width: "18px", height: "18px" }} />
                                                        <Typography style={{ marginLeft: "8px", fontSize: "14px", fontWeight: 400, fontFamily: "Inter, sans-serif" }} >Remove Mapping</Typography>
                                                    </Box>
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={3}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                            <Pagination
                                                count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                page={this.state.currentPage}
                                                siblingCount={0}
                                                boundaryCount={1}
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                        {...item}
                                                        style={{
                                                            textDecoration: 'underline',
                                                            borderRadius: 0,
                                                            background: "none",
                                                            fontFamily: "Inter, sans-serif",
                                                            fontSize: "12px",
                                                            fontWeight: 400,
                                                            color: "#979797"
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                </TableRow>
                                <TableRow style={{ height: "30px" }}></TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {renderModal()}
                {RenderMappingModal()}
            </Box>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Usernavbar />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.Catalougedata()}
                </SideBar>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    mainContainer: {
        width: "94%",
        margin: "0px 20px 0px 45px",
        fontFamily: "Inter, sans-serif",
    },
    Tbody: {
        padding: "20px",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",
        fontFamily: "Inter, sans-serif",
    },
    heading: {
        fontFamily: "Inter, sans-serif",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "26px",
    },
    checkoutButton: {
        backgroundColor: "#ff6600",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        padding: "7px 24px",
        width: "151px",
        textTransform: "none" as const,
    },
    tableHead: {
        backgroundColor: "#f3f4f6",
    },

    modal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 708,
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        height: "490px",
    },
    formField: {
        marginBottom: "16px",
        width: "660px",
        margin: "0px auto"
    },
    saveButton: {
        backgroundColor: "#FF6600",
        color: "white",
        align: "right",
        fontSize : "16px",
        fontWeight: 700,
        fontFamily : "Inter, sans-serif",
        boxShadow : "none",
        textTransform: "none" as const,
        borderRadius:"12px"
    },
    TableCell: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
        borderBottom: "none",
        fontFamily: "Inter, sans-serif",
    },
    title: {
        fontFamily: "Inter,sans-serif",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "20px"
    },
    size: {
        fontFamily: "Inter,sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        marginTop: "4px"
    },
    type: {
        fontFamily: "Inter,sans-serif",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "20px",
    },
    category: {
        fontFamily: "Inter,sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        marginTop: "6px"
    },
    breadcrumbContainer: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Inter, sans-serif",
        marginBottom: "16px",
    },
    link: {
        textDecoration: "none",
        fontSize: "14px",
        fontWeight: 500,
        color: "#007BFF",
        cursor: "pointer",
    },
    current: {
        textDecoration: "none",
        fontSize: "14px",
        fontWeight: 700,
        color: "#333",
    },
    Nobutton:{
        backgroundColor :"#BDC1CA",
        color : "#FFFFFF",
        fontFamily : "Inter, sans-serif",
        fontWeight : 700,
        fontSize : "16px",
        borderRadius : "12px",
        boxShadow : "none",
        textTransform: "none" as const,
        height : "44px",
        width : "61px"
    },
    Yesbutton :{
        backgroundColor :"#DE3B40",
        color : "#FFFFFF",
        fontFamily : "Inter, sans-serif",
        fontWeight : 700,
        fontSize : "16px",
        borderRadius : "12px",
        boxShadow : "none",
        textTransform: "none" as const,
            height : "44px",
        width : "61px"
    },
    separator: {
        fontSize: "14px",
        fontWeight: 400,
        margin: "0 4px",
        color: "#888",
    },
    modaltitle: {
        fontSize: "30px",
        fontWeight: 700,
        marginLeft: "30px"
    },
    modaltitle1:{
        fontFamily : "Inter,sans-serif",
        fontSize : "14px",
        fontWeight : 400
    },
    modaltitle11 : {
        fontFamily : "Inter,sans-serif",
        fontSize : "14px",
        fontWeight : 700
    }
}
// Customizable Area End
